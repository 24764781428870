<template>
  <section>
    <v-container>
      <v-row v-if="validateBusinessRules">
        <!-- Nome -->
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            class="white"
            hide-details="true"
            ref="nome"
            label="Nome do modelo"
            placeholder="Escreva o nome do modelo"
            outlined
            dense
            v-model.trim="modelo.nome"
            data-vv-as="nome"
            persistent-hint
            :hint="errors.first('basic-informations.nome')"
            :error="
              errors.collect('basic-informations.nome').length ? true : false
            "
            v-validate="'required|min:3'"
            data-vv-scope="basic-informations"
            data-vv-validate-on="blur|change"
            id="nome"
            name="nome"
            :loading="loading.loadingBasicInformations"
          ></v-text-field>
        </v-col>
        <!-- Responsável -->
        <v-col v-if="userPermissions" cols="12" sm="12" md="12">
          <v-select
            class="white"
            hide-details="true"
            label="Responsável"
            :items="responsaveis"
            item-text="nome"
            item-value="id"
            placeholder="Selecione um usuário"
            outlined
            dense
            v-model="modelo.id_usuario"
            :loading="loadingResponsables"
          ></v-select>
        </v-col>
        <!-- Escalas -->
        <!-- <v-col cols="12" sm="12" md="12" class="mb-3">
          <v-select
            class="white"
            ref="escala"
            label="Escala"
            placeholder="Selecione um escala"
            :items="escalas"
            v-model="escala"
            return-object
            item-text="titulo"
            item-value="sigla"
            :loading="loadingEscala"
            dense
            outlined
            data-vv-as="escala"
            persistent-hint
            :hint="errors.first('basic-informations.escala')"
            :error="
              errors.collect('basic-informations.escala').length ? true : false
            "
            v-validate="'required'"
            data-vv-scope="basic-informations"
            data-vv-validate-on="blur|change"
            id="escala"
            name="escala"
            messages="Escolha escalas que serão utilizadas nas perguntas de Favorabilidade"
          ></v-select>
        </v-col> -->
        <!-- Descrição -->
        <v-col cols="12" sm="12" md="12">
          <v-textarea
            class="white"
            hide-details="true"
            ref="descricao"
            label="Descrição"
            outlined
            dense
            v-model.trim="modelo.descricao"
            data-vv-as="descrição"
            persistent-hint
            :hint="errors.first('basic-informations.descricao')"
            :error="
              errors.collect('basic-informations.descricao').length
                ? true
                : false
            "
            v-validate="'required'"
            data-vv-scope="basic-informations"
            data-vv-validate-on="blur|change"
            id="descricao"
            name="descricao"
            :loading="loading.loadingBasicInformations"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Caso esteja em modo visualização -->
      <v-list v-else>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Nome do modelo</v-list-item-title
            >
            <v-list-item-subtitle class="text-body-1">{{
              modelo.nome
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Descrição</v-list-item-title
            >
            <v-list-item-subtitle class="text-body-1">{{
              modelo.descricao
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "BasicInformations",
  inject: ["$validator"],
  props: ["validateBusinessRules"],
  computed: {
    modelo: {
      get() {
        return this.$store.getters["pcoModelos/getModelo"];
      },
    },
    responsaveis: {
      get() {
        return this.$store.getters["usuarios/getResponsaveis"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
    loadingResponsables: {
      get() {
        return this.$store.getters["usuarios/getLoading"];
      },
    },
    loadingEscala: {
      get() {
        return this.$store.getters["escalas/getLoading"];
      },
    },
    userRole: {
      get() {
        return this.$store.getters["auth/getRole"];
      },
    },
    // escalas: {
    //   get() {
    //     return this.$store.getters["escalas/getEscalas"];
    //   },
    // },
    // escala: {
    //   get() {
    //     return this.$store.getters["escalas/getEscala"];
    //   },
    //   set(value) {
    //     return this.$store.dispatch("escalas/setEscala", value);
    //   },
    // },
    userPermissions() {
      if (
        this.userRole == "Administrador" ||
        this.userRole == "Desenvolvedor"
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style></style>

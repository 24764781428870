import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VContainer,[(_vm.validateBusinessRules)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],ref:"nome",staticClass:"white",attrs:{"hide-details":"true","label":"Nome do modelo","placeholder":"Escreva o nome do modelo","outlined":"","dense":"","data-vv-as":"nome","persistent-hint":"","hint":_vm.errors.first('basic-informations.nome'),"error":_vm.errors.collect('basic-informations.nome').length ? true : false,"data-vv-scope":"basic-informations","data-vv-validate-on":"blur|change","id":"nome","name":"nome","loading":_vm.loading.loadingBasicInformations},model:{value:(_vm.modelo.nome),callback:function ($$v) {_vm.$set(_vm.modelo, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelo.nome"}})],1),(_vm.userPermissions)?_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VSelect,{staticClass:"white",attrs:{"hide-details":"true","label":"Responsável","items":_vm.responsaveis,"item-text":"nome","item-value":"id","placeholder":"Selecione um usuário","outlined":"","dense":"","loading":_vm.loadingResponsables},model:{value:(_vm.modelo.id_usuario),callback:function ($$v) {_vm.$set(_vm.modelo, "id_usuario", $$v)},expression:"modelo.id_usuario"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VTextarea,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"descricao",staticClass:"white",attrs:{"hide-details":"true","label":"Descrição","outlined":"","dense":"","data-vv-as":"descrição","persistent-hint":"","hint":_vm.errors.first('basic-informations.descricao'),"error":_vm.errors.collect('basic-informations.descricao').length
              ? true
              : false,"data-vv-scope":"basic-informations","data-vv-validate-on":"blur|change","id":"descricao","name":"descricao","loading":_vm.loading.loadingBasicInformations},model:{value:(_vm.modelo.descricao),callback:function ($$v) {_vm.$set(_vm.modelo, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelo.descricao"}})],1)],1):_c(VList,[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-medium"},[_vm._v("Nome do modelo")]),_c(VListItemSubtitle,{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.modelo.nome))])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-medium"},[_vm._v("Descrição")]),_c(VListItemSubtitle,{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.modelo.descricao))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }